$(document).ready(function(){
    $('BODY').on('click', '.is-invalid', function(){
        $(this).removeClass('is-invalid');
    })

    // IMAGES DRAG N DROP AND CHANGEd
    jQuery(document).on('change', 'INPUT.imgPreviewInput', function () {
        //console.log('Работа превью');
        var usObj = jQuery(this),
            input = jQuery(this)[0];
        if (input.files && input.files[0]) {
            if (input.files[0].type.match('image.*')) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    usObj.parents('.imgPreview').css('background-image', 'url('+e.target.result+')');
                }
                reader.readAsDataURL(input.files[0]);
            } else {
                jQuery(this).parents('.imgPreview').css('background-image', config('app.no_image'));
                //console.log('ошибка, не изображение');
            }
        } else {
            jQuery(this).parents('.imgPreview').css('background-image', config('app.no_image'));
            //console.log('хьюстон у нас проблема');
        }
    });

    jQuery('BODY').on('keypress', 'INPUT.numberInput', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];

        //console.log(String.fromCharCode(e.which));
        //console.log(list.indexOf(String.fromCharCode(e.which)));
        if(String.fromCharCode(e.which) == '.'){
            if(val.length <= 0){
                val = '0.';
                jQuery(this).val('0.');
            }
            if(val.indexOf('.') >= 0){
                return false;
            }
        }
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });

    jQuery('BODY').on('keypress', 'INPUT.numberInputInt', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });

    jQuery('BODY').on('change', 'INPUT.numberInput, INPUT.numberInputInt', function(e){
        var numberText = jQuery(this).val();
        numberText = numberText.replace(/ /, '');
        numberText = parseFloat(numberText);
        if(isNaN(numberText)){
            numberText = 0;
        }
        jQuery(this).val(numberText);
        return false;
    });
});